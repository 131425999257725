import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Agency = lazy(() => import('../../container/queue/Agency'));
const AgencyQueue = lazy(() => import('../../container/queue/AgencyQueue'));
const Queue = lazy(() => import('../../container/queue'));

function QueueRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={Queue} />
      {user.role === 'admin' && <Route exact path={`${path}/agency`} component={Agency} />}
      {user.role === 'admin' && <Route exact path={`${path}/agency/:agencyId`} component={AgencyQueue} />}
      {user.role === 'admin' && <Route exact path={`${path}/report`} component={NotFound} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default QueueRoutes;
