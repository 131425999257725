import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const menus = lazy(() => import('../../container/menu'));
const Create = lazy(() => import('../../container/menu/Create'));
const Edit = lazy(() => import('../../container/menu/Edit'));

function ArticleRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={menus} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:menuId/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ArticleRoutes;
