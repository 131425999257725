import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const IkmServiceList = lazy(() => import('../../container/ikm-service/index'));
const Show = lazy(() => import('../../container/ikm-service/Show'));

function IkmRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={IkmServiceList} />
      <Route exact path={`${path}/:serviceId`} component={Show} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default IkmRoutes;
