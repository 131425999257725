import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  getChatListBegin,
  getChatListSuccess,
  getChatListErr,
  updateLastChatSuccess,
  readUserChatSuccess,
  storeNewChatSuccess,
} = actions;

const getChatList = () => {
  return async (dispatch) => {
    try {
      await dispatch(getChatListBegin());
      const response = await DataService.get('/chat');
      dispatch(getChatListSuccess({ chats: response.data.users, totalUnread: response.data.total_unread }));
    } catch (err) {
      dispatch(getChatListErr(err));
    }
  };
};

const updateLastChat = (userId, lastChat) => {
  return async (dispatch) => {
    try {
      dispatch(updateLastChatSuccess({ userId, lastChat }));
    } catch (err) {
      console.log(err);
    }
  };
};

const readUserChat = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(readUserChatSuccess(userId));
    } catch (err) {
      console.log(err);
    }
  };
};

const storeNewChat = (chat) => {
  return async (dispatch) => {
    try {
      dispatch(storeNewChatSuccess(chat));
    } catch (err) {
      console.log(err);
    }
  };
};

export { getChatList, updateLastChat, readUserChat, storeNewChat };
