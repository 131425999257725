import React from 'react';
import { Badge, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const role = useSelector((state) => state.auth.user.role);
  const totalUnread = useSelector((state) => state.chatList.totalUnread);
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit[1] !== '' ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 0) setOpenKeys([]);
  };

  const selectedKeys = () => {
    const keys = [];
    switch (mainPathSplit.length) {
      case 1:
        if (mainPathSplit[0] === '') {
          keys[0] = 'dashboard';
        } else {
          [keys[0]] = mainPathSplit;
        }
        break;

      case 3:
        if (mainPathSplit[1] === 'queue') {
          if (['agency', 'report'].includes(mainPathSplit[2])) {
            keys[0] = `queue-${mainPathSplit[2]}`;
          }
        }

        if (mainPathSplit[1] === 'setting') {
          if (['app-general', 'contact', 'homepage', 'service-flows', 'home-features'].includes(mainPathSplit[2])) {
            keys[0] = `${mainPathSplit[2]}`;
          }
        }
        break;

      case 4:
        if (mainPathSplit[1] === 'queue' && mainPathSplit[2] === 'agency') {
          keys[0] = `queue-agency`;
        }
        break;

      default:
        [, keys[0]] = mainPathSplit;
        break;
    }
    return keys;
  };

  const items = [
    {
      type: 'group',
      label: 'Main Menu',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}dashboard`}>
              Dashboard
            </NavLink>
          ),
          key: 'dashboard',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}dashboard`}>
              <FeatherIcon icon="grid" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}agency`}>
              Instansi
            </NavLink>
          ),
          key: 'agency',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}agency`}>
              <FeatherIcon icon="slack" />
            </NavLink>
          ),
        },
        (role === 'admin' || role === 'instansi') && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}service`}>
              Layanan
            </NavLink>
          ),
          key: 'service',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}service`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}article`}>
              Artikel
            </NavLink>
          ),
          key: 'article',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}article`}>
              <FeatherIcon icon="file" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}page`}>
              Halaman
            </NavLink>
          ),
          key: 'page',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}page`}>
              <FeatherIcon icon="file" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}complaint`}>
              Pengaduan
            </NavLink>
          ),
          key: 'complaint',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}complaint`}>
              <FeatherIcon icon="layers" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: 'IKM',
          key: 'ikm',
          icon: !topMenu && <FeatherIcon icon="star" />,
          children: [
            {
              label: (
                <NavLink onClick={toggleCollapsed} to={`${path}ikm-agency`}>
                  Instansi
                </NavLink>
              ),
              key: 'ikm-agency',
            },
            {
              label: (
                <NavLink onClick={toggleCollapsed} to={`${path}ikm-service`}>
                  Layanan
                </NavLink>
              ),
              key: 'ikm-service',
            },
          ],
        },
        role === 'instansi' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}ikm-service`}>
              IKM Layanan
            </NavLink>
          ),
          key: 'ikm-service',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}ikm-service`}>
              <FeatherIcon icon="star" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          type: 'group',
          label: 'Antrian',
          children: [
            {
              label: (
                <NavLink onClick={toggleCollapsed} to={`${path}queue`}>
                  Semua Antrian
                </NavLink>
              ),
              key: 'queue',
              icon: !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}queue`}>
                  <FeatherIcon icon="list" />
                </NavLink>
              ),
            },
            {
              label: (
                <NavLink onClick={toggleCollapsed} to={`${path}queue/agency`}>
                  Antrian Instansi
                </NavLink>
              ),
              key: 'queue-agency',
              icon: !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}queue/agency`}>
                  <FeatherIcon icon="bar-chart-2" />
                </NavLink>
              ),
            },
            {
              label: (
                <NavLink onClick={toggleCollapsed} to={`${path}service-booking`}>
                  Antrian Layanan
                </NavLink>
              ),
              key: 'service-booking',
              icon: !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}service-booking`}>
                  <FeatherIcon icon="list" />
                </NavLink>
              ),
            },
            {
              label: 'Laporan',
              key: 'report',
              icon: !topMenu && <FeatherIcon icon="book" />,
              children: [
                {
                  label: (
                    <NavLink onClick={toggleCollapsed} to={`${path}admin-general-report`}>
                      Laporan Baku
                    </NavLink>
                  ),
                  key: 'admin-general-report',
                },
                {
                  label: (
                    <NavLink onClick={toggleCollapsed} to={`${path}admin-custom-report`}>
                      Laporan Kustom
                    </NavLink>
                  ),
                  key: 'admin-custom-report',
                },
              ],
            },
          ],
        },
        (role === 'instansi' || role === 'operator') && {
          label: (
            <div className="d-flex">
              <NavLink onClick={toggleCollapsed} to={`${path}chat`}>
                Live Chat
              </NavLink>
              {totalUnread !== 0 && (
                <span>
                  <Badge className="badge-success" count={totalUnread} color="#faad14" />
                </span>
              )}
            </div>
          ),
          key: 'chat',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}chat`}>
              <FeatherIcon icon="message-square" />
            </NavLink>
          ),
        },
        role === 'operator' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}today-queue`}>
              Antrian Hari Ini
            </NavLink>
          ),
          key: 'today-queue',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}today-queue`}>
              <FeatherIcon icon="list" />
            </NavLink>
          ),
        },
      ],
    },
    role === 'instansi' && {
      type: 'group',
      label: 'Antrian',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}today-queue`}>
              Antrian Hari Ini
            </NavLink>
          ),
          key: 'today-queue',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}today-queue`}>
              <FeatherIcon icon="list" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}queue`}>
              Semua Antrian
            </NavLink>
          ),
          key: 'queue',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}queue`}>
              <FeatherIcon icon="list" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}service-booking`}>
              Antrian Layanan
            </NavLink>
          ),
          key: 'service-booking',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}service-booking`}>
              <FeatherIcon icon="list" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}queue-report`}>
              Laporan
            </NavLink>
          ),
          key: 'queue-report',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}queue-report`}>
              <FeatherIcon icon="book" />
            </NavLink>
          ),
        },
      ],
    },

    role === 'instansi' && {
      type: 'group',
      label: 'Pengguna',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}operator-user`}>
              Operator
            </NavLink>
          ),
          key: 'operator-user',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}operator-user`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
      ],
    },

    role === 'admin' && {
      type: 'group',
      label: 'Pengguna',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}agency-user`}>
              Instansi
            </NavLink>
          ),
          key: 'agency-user',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}agency-user`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}citizen`}>
              Masyarakat
            </NavLink>
          ),
          key: 'citizen',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}citizen`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}operator-user`}>
              Operator
            </NavLink>
          ),
          key: 'operator-user',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}operator-user`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
      ],
    },

    role !== 'operator' && {
      type: 'group',
      label: 'Pengaturan',
      children: [
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}menu`}>
              Menu
            </NavLink>
          ),
          key: 'menu',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}menu`}>
              <FeatherIcon icon="menu" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: 'Setting',
          key: 'setting',
          icon: !topMenu && <FeatherIcon icon="settings" />,
          children: [
            {
              label: (
                <NavLink className="menuItem-iocn" to={`${path}setting/app-general`}>
                  Aplikasi
                </NavLink>
              ),
              key: 'app-general',
            },
            {
              label: (
                <NavLink className="menuItem-iocn" to={`${path}setting/homepage`}>
                  Homepage
                </NavLink>
              ),
              key: 'homepage',
            },
            {
              label: (
                <NavLink className="menuItem-iocn" to={`${path}setting/service-flows`}>
                  Alur Pelayanan
                </NavLink>
              ),
              key: 'service-flows',
            },
            {
              label: (
                <NavLink className="menuItem-iocn" to={`${path}setting/home-features`}>
                  Fasilitas
                </NavLink>
              ),
              key: 'home-features',
            },
          ],
        },
        role === 'instansi' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}agency-setting`}>
              Setting
            </NavLink>
          ),
          key: 'agency-setting',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}agency-setting`}>
              <FeatherIcon icon="settings" />
            </NavLink>
          ),
        },
      ],
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={!topMenu ? selectedKeys() : []}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 1 ? mainPathSplit[0] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      selectedKeys={selectedKeys()}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
