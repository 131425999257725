import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const services = lazy(() => import('../../container/service'));
const Create = lazy(() => import('../../container/service/Create'));
const Edit = lazy(() => import('../../container/service/Edit'));
const Show = lazy(() => import('../../container/service/Show'));

function ServiceRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={services} />
      {user.role === 'instansi' && <Route exact path={`${path}/create`} component={Create} />}
      <Route exact path={`${path}/:serviceId`} component={Show} />
      {user.role === 'instansi' && <Route exact path={`${path}/:serviceId/edit`} component={Edit} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ServiceRoutes;
