import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const articles = lazy(() => import('../../container/article'));
const Create = lazy(() => import('../../container/article/Create'));
const Edit = lazy(() => import('../../container/article/Edit'));
const Show = lazy(() => import('../../container/article/Show'));

function ArticleRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={articles} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:articleId`} component={Show} />
      <Route exact path={`${path}/:articleId/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ArticleRoutes;
