import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const pages = lazy(() => import('../../container/page'));
const Create = lazy(() => import('../../container/page/Create'));
const Edit = lazy(() => import('../../container/page/Edit'));
const Show = lazy(() => import('../../container/page/Show'));

function ArticleRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={pages} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:pageSlug`} component={Show} />
      <Route exact path={`${path}/:pageSlug/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ArticleRoutes;
