import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  getSettingBegin,
  getSettingSuccess,
  getSettingErr,
  updateSettingBegin,
  updateSettingSuccess,
  updateSettingErr,
} = actions;

const getSetting = () => {
  return async (dispatch) => {
    try {
      dispatch(getSettingBegin());

      const response = await DataService.get('/setting');
      dispatch(
        getSettingSuccess({
          appName: response.data.setting.app_name,
          wideLogo: response.data.setting.wide_logo_url,
          rectangleLogo: response.data.setting.rectangle_logo_url,
          adminAuthImage: response.data.setting.admin_auth_image_url,
          publicAuthBackground: response.data.setting.public_auth_background_url,
          videoScreen: response.data.setting.video_screen_url,
          heroDescription: response.data.setting.hero_description,
          heroVideo: response.data.setting.hero_video,
          heroImage1: response.data.setting.hero_image_1_url,
          heroImage2: response.data.setting.hero_image_2_url,
          aboutDescription: response.data.setting.about_description,
          aboutVideo: response.data.setting.about_video,
          aboutImage: response.data.setting.about_image_url,
          address: response.data.setting.address,
          copyrightText: response.data.setting.copyright_text,
          phone: response.data.setting.phone,
          email: response.data.setting.email,
          embedGmap: response.data.setting.embed_gmap,
          // Social Media
          playStore: response.data.setting.play_store,
          appleStore: response.data.setting.apple_store,
          youtube: response.data.setting.youtube,
          facebook: response.data.setting.facebook,
          instagram: response.data.setting.instagram,
          twitter: response.data.setting.twitter,
          tiktok: response.data.setting.tiktok,
        }),
      );
    } catch (err) {
      dispatch(getSettingErr(err));
    }
  };
};

const updateSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/setting', data);

      dispatch(
        updateSettingSuccess({
          appName: response.data.setting.app_name,
          wideLogo: response.data.setting.wide_logo_url,
          rectangleLogo: response.data.setting.rectangle_logo_url,
          adminAuthImage: response.data.setting.admin_auth_image_url,
          publicAuthBackground: response.data.setting.public_auth_background_url,
          videoScreen: response.data.setting.video_screen_url,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
      return err;
    }
  };
};

const updateContactSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/contact-setting', data);

      dispatch(
        updateSettingSuccess({
          phone: response.data.setting.phone,
          email: response.data.setting.email,
          embedGmap: response.data.setting.embed_gmap,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
      return err;
    }
  };
};

const updateHomepageSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/homepage-setting', data);

      dispatch(
        updateSettingSuccess({
          heroDescription: response.data.setting.hero_description,
          heroVideo: response.data.setting.hero_video,
          heroImage1: response.data.setting.hero_image_1_url,
          heroImage2: response.data.setting.hero_image_2_url,
          aboutDescription: response.data.setting.about_description,
          aboutVideo: response.data.setting.about_video,
          aboutImage: response.data.setting.about_image_url,
          copyrightText: response.data.setting.copyright_text,
          address: response.data.setting.address,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
      return err;
    }
  };
};

export { getSetting, updateSetting, updateContactSetting, updateHomepageSetting };
