import actions from './actions';

const initialStateChatList = {
  data: [],
  totalUnread: 0,
  loading: false,
  error: null,
};

const {
  GET_CHAT_LIST_BEGIN,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_ERR,
  UPDATE_LAST_CHAT_SUCCESS,
  READ_USER_CHAT_SUCCESS,
  STORE_NEW_CHAT_SUCCESS,
} = actions;

const chatListReducer = (state = initialStateChatList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CHAT_LIST_BEGIN:
      return {
        ...initialStateChatList,
        loading: true,
      };
    case GET_CHAT_LIST_SUCCESS:
      return {
        ...initialStateChatList,
        data: data.chats,
        totalUnread: data.totalUnread,
        loading: false,
      };
    case GET_CHAT_LIST_ERR:
      return {
        ...initialStateChatList,
        error: err,
        loading: false,
      };
    case UPDATE_LAST_CHAT_SUCCESS:
      return {
        ...state,
        data: state.data.map((user) => ({
          ...user,
          last_chat: user.id === parseInt(data.userId) ? data.lastChat : user.last_chat,
        })),
      };
    case READ_USER_CHAT_SUCCESS: {
      const readUser = state.data.filter((user) => user.id === parseInt(data))[0];
      return {
        ...state,
        totalUnread: state.totalUnread - readUser.unread_chat,
        data: state.data.map((user) => {
          return {
            ...user,
            unread_chat: user.id === parseInt(data) ? 0 : parseInt(user.unread_chat),
          };
        }),
      };
    }
    case STORE_NEW_CHAT_SUCCESS: {
      const newChat = JSON.parse(data);
      return {
        ...state,
        totalUnread: state.totalUnread + 1,
        data: state.data.map((user) => {
          return {
            ...user,
            last_chat: user.id === parseInt(newChat.user_id) ? newChat : user.last_chat,
            unread_chat: user.id === parseInt(newChat.user_id) ? user.unread_chat + 1 : user.unread_chat,
          };
        }),
      };
    }
    default:
      return state;
  }
};

export { chatListReducer };
