const actions = {
  GET_CHAT_LIST_BEGIN: 'GET_CHAT_LIST_BEGIN',
  GET_CHAT_LIST_SUCCESS: 'GET_CHAT_LIST_SUCCESS',
  GET_CHAT_LIST_ERR: 'GET_CHAT_LIST_ERR',
  UPDATE_LAST_CHAT_SUCCESS: 'UPDATE_LAST_CHAT_SUCCESS',
  READ_USER_CHAT_SUCCESS: 'READ_USER_CHAT_SUCCESS',
  STORE_NEW_CHAT_SUCCESS: 'STORE_NEW_CHAT_SUCCESS',

  getChatListBegin: () => {
    return {
      type: actions.GET_CHAT_LIST_BEGIN,
    };
  },

  getChatListSuccess: (data) => {
    return {
      type: actions.GET_CHAT_LIST_SUCCESS,
      data,
    };
  },

  getChatListErr: (err) => {
    return {
      type: actions.GET_CHAT_LIST_ERR,
      err,
    };
  },

  updateLastChatSuccess: (data) => {
    return {
      type: actions.UPDATE_LAST_CHAT_SUCCESS,
      data,
    };
  },

  readUserChatSuccess: (data) => {
    return {
      type: actions.READ_USER_CHAT_SUCCESS,
      data,
    };
  },

  storeNewChatSuccess: (data) => {
    return {
      type: actions.STORE_NEW_CHAT_SUCCESS,
      data,
    };
  },
};

export default actions;
