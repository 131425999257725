import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const TodayQueue = lazy(() => import('../../container/today-queue'));

function TodayQueueRoutes() {
  const { path } = useRouteMatch();
  // const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={TodayQueue} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default TodayQueueRoutes;
