import React, { useEffect, useState } from 'react';

import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Pusher from 'pusher-js';

import { getSetting } from './redux/setting/actionCreator';
import { getChatList, storeNewChat } from './redux/chat/actionCreator';
import store from './redux/store';
import Home from './routes/home';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';

const { theme } = config;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  encrypted: true,
});

function ProviderConfig() {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, user } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      user: state.auth.user,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    if (isLoggedIn && (user.role === 'instansi' || user.role === 'operator')) {
      dispatch(getChatList());
    }
  }, [user]);

  useEffect(() => {
    dispatch(getSetting());
    const channel = pusher.subscribe('my-channel');
    channel.bind('my-event', ({ data }) => {
      let agencyId;

      if (user?.role === 'operator') {
        agencyId = user?.agency?.agency_user?.agency_id;
      } else if (user?.role === 'instansi') {
        agencyId = user?.agency_user?.agency_id;
      }

      if (data.type === 'new chat' && data.chat_for === `agency-chat-${agencyId}`) {
        dispatch(storeNewChat(data.chat));
        if (data.is_new) dispatch(getChatList());
      }
    });

    return () => {
      pusher.unsubscribe('my-channel');
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/" component={Home} />}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/` ||
              path === `${process.env.PUBLIC_URL}/sign-in`) && <Redirect to="/dashboard" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
