import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const ServiceBooking = lazy(() => import('../../container/service-booking'));
const ServiceBookingQueue = lazy(() => import('../../container/service-booking/ServiceBookingQueue'));

function ServiceBookingRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={ServiceBooking} />
      <Route exact path={`${path}/:serviceId`} component={ServiceBookingQueue} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ServiceBookingRoutes;
