import actions from './actions';

const {
  GET_SETTING_BEGIN,
  GET_SETTING_SUCCESS,
  GET_SETTING_ERR,
  UPDATE_SETTING_BEGIN,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_ERR,
} = actions;

const initState = {
  appName: null,
  wideLogo: null,
  rectangleLogo: null,
  phone: null,
  email: null,
  embedGmap: null,
  adminAuthImage: null,
  publicAuthBackground: null,
  videoScreen: null,
  copyrightText: null,
  heroDescription: null,
  heroVideo: null,
  heroImage1: null,
  heroImage2: null,
  aboutDescription: null,
  aboutVideo: null,
  aboutImage: null,
  loading: false,
  error: null,
};

const settingReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SETTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case GET_SETTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SETTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case UPDATE_SETTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { settingReducer };
