import { notification } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { removeItem, setItem, getItem } from '../../utility/localStorageControl';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  updateProfileBegin,
  updateProfileSuccess,
  updateProfileErr,
  storeFcmTokenSuccess,
} = actions;

const login = (data) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(loginBegin());
      const response = await DataService.post('/login', data);

      if (response.data.user.role === 'masyarakat') {
        dispatch(loginErr({}));

        notification.error({
          message: 'Tidak bisa login menggunakan akun masyarakat !',
        });
      } else {
        setItem('access_token', response.data.token);
        setItem('user', response.data.user);

        Cookies.set('logedIn', true);
        await dispatch(loginSuccess(response.data.user));
        if (response.data.user.role === 'instansi') {
          const { fcmToken } = getState().auth;
          navigator.sendBeacon(
            `${process.env.REACT_APP_API_ENDPOINT}/subscribe-topic?topic=agency-chat-${response.data.user.agency_user.agency_id}&registration_token=${fcmToken}`,
          );
        }
        notification.success({
          message: 'Login berhasil !',
        });
        window.location.reload();
      }
    } catch (err) {
      notification.error({
        message: 'Login gagal !',
      });
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(logoutBegin());
      await DataService.post('/logout');
      const { user, fcmToken } = getState().auth;
      if (user.role === 'instansi') {
        navigator.sendBeacon(
          `${process.env.REACT_APP_API_ENDPOINT}/unsubscribe-topic?topic=agency-chat-${user.agency_user.agency_id}&registration_token=${fcmToken}`,
        );
      }
      removeItem('access_token');
      removeItem('user');

      Cookies.remove('logedIn');
      dispatch(logoutSuccess(null));
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Logout gagal !',
      });
      dispatch(logoutErr(err));
    }
  };
};

const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(updateProfileBegin());
      const response = await DataService.post('/profile', data);

      setItem('user', response.data.user);

      await dispatch(updateProfileSuccess(response.data.user));

      notification.success({
        message: 'Profil berhasil diperbaharui !',
      });
    } catch (err) {
      notification.error({
        message: 'Gagal memperbaharui profil !',
      });
      dispatch(updateProfileErr(err));
    }
  };
};

const updateProfilePicture = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(updateProfileBegin());
      const response = await DataService.post('/profile-picture', data);

      setItem('user', {
        ...getItem('user'),
        profile_picture: response.data.profile_picture,
        profile_picture_url: response.data.profile_picture_url,
      });

      await dispatch(
        updateProfileSuccess({
          ...getItem('user'),
          profile_picture: response.data.profile_picture,
          profile_picture_url: response.data.profile_picture_url,
        }),
      );

      notification.success({
        message: 'Profil berhasil diperbaharui !',
      });
    } catch (err) {
      notification.error({
        message: 'Gagal memperbaharui profil !',
      });
      dispatch(updateProfileErr(err));
    }
  };
};

const storeFcmToken = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(storeFcmTokenSuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export { login, logOut, updateProfile, updateProfilePicture, storeFcmToken };
