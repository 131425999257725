import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const agencies = lazy(() => import('../../container/user/agency'));
const Create = lazy(() => import('../../container/user/agency/Create'));
const Edit = lazy(() => import('../../container/user/agency/Edit'));

function AgencyUserRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={agencies} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:agencyUserId/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default AgencyUserRoutes;
