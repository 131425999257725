import { combineReducers } from 'redux';
import { settingReducer } from './setting/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { chatListReducer } from './chat/reducers';

const rootReducers = combineReducers({
  setting: settingReducer,
  auth: authReducer,
  ChangeLayoutMode,
  chatList: chatListReducer,
});

export default rootReducers;
