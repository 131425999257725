import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dashboard from './dashboard';
import Agency from './agency';
import AgencyUser from './agency-user';
import Operator from './operator';
import Article from './article';
import Citizen from './citizen';
import Menu from './menu';
import Page from './page';
import Service from './service';
import Queue from './queue';
import TodayQueue from './today-queue';
import QueueReport from './report';
import Ikm from './ikm';
import ServiceBooking from './service-booking';
import withAdminLayout from '../../layout/withAdminLayout';

const AgencySetting = lazy(() => import('../../container/setting/Agency'));
const Chat = lazy(() => import('../../container/chat'));
const ComplaintReply = lazy(() => import('../../container/complaint/reply'));
const Complaint = lazy(() => import('../../container/complaint'));
const NotFound = lazy(() => import('../../container/pages/404'));
const ProfileSetting = lazy(() => import('../../container/user/ProfileSetting'));
const Setting = lazy(() => import('../../container/setting/index'));
const IkmAgency = lazy(() => import('../../container/ikm-agency/index'));

// Report
const AdminGeneralReport = lazy(() => import('../../container/admin-report/general'));
const AdminCustomReport = lazy(() => import('../../container/admin-report/custom'));

const Home = () => {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={`${path}dashboard`} component={Dashboard} />
        {user.role === 'admin' && <Route path={`${path}agency`} component={Agency} />}
        {user.role === 'admin' && <Route path={`${path}agency-user`} component={AgencyUser} />}
        {(user.role === 'admin' || user.role === 'instansi') && (
          <Route path={`${path}operator-user`} component={Operator} />
        )}
        <Route path={`${path}ikm-service`} component={Ikm} />
        <Route path={`${path}ikm-agency`} component={IkmAgency} />
        {user.role === 'admin' && <Route path={`${path}article`} component={Article} />}
        <Route path={`${path}chat`} component={Chat} />
        <Route path={`${path}citizen`} component={Citizen} />
        <Route exact path={`${path}complaint`} component={Complaint} />
        <Route exact path={`${path}complaint/:complaintId/reply`} component={ComplaintReply} />
        {user.role === 'admin' && <Route path={`${path}menu`} component={Menu} />}
        {user.role === 'admin' && <Route path={`${path}page`} component={Page} />}
        <Route path={`${path}profile`} component={ProfileSetting} />
        <Route path={`${path}setting`} component={Setting} />
        <Route path={`${path}service`} component={Service} />
        <Route path={`${path}queue`} component={Queue} />
        <Route path={`${path}service-booking`} component={ServiceBooking} />
        <Route path={`${path}today-queue`} component={TodayQueue} />
        {user.role === 'instansi' && <Route path={`${path}agency-setting`} component={AgencySetting} />}
        {/* Queue Report */}
        <Route path={`${path}queue-report`} component={QueueReport} />
        <Route path={`${path}admin-general-report`} component={AdminGeneralReport} />
        <Route path={`${path}admin-custom-report`} component={AdminCustomReport} />

        <Route exact path={['/sign-in', '/']}>
          <Redirect to={`${path}dashboard`} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Home);
